import { useContext, useEffect, useRef, useState } from 'react';
import './DungeonEdit.css';
import ContentContext from '../../Contexts/ContentContext';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ContentRequests from '../../Requests/content-requests';
import GenericEditor from '../Generics/GenericEditor';
import DungeonRoomListItem from '../DungeonRooms/DungeonRoomListItem';
import DungeonRoomEdit, { CreateEmptyRoom } from '../DungeonRooms/DungeonRoomEdit';

const DungeonEditTabs = {
    MetaData: 1,
    Rooms: 2
}

function DungeonEdit() {
    const { content, setContent } = useContext(ContentContext);
    let [dungeonOriginal, setDungeonOriginal] = useState(null);
    let [selectedTab, setSelectedTab] = useState(DungeonEditTabs.MetaData);
    let [editingRoom, setEditingRoom] = useState(false);
    let [editingRoomIndex, setEditingRoomIndex] = useState(0);
    let [editingRoomName, setEditingRoomName] = useState('');
    let [dungeon, setDungeon] = useState(null);
    let [template, setTemplate] = useState(null);
    let [index, setIndex] = useState(-1);
    let hasChangesRef = useRef(false);
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!content) return;

        let dungeonOriginalTemp = content.Dungeons.find(x => x.Id == id);
        index = content.Dungeons.indexOf(dungeonOriginalTemp);
        setIndex(index);

        if (dungeonOriginalTemp == null) return navigate('/dungeons');
        setDungeonOriginal(dungeonOriginalTemp);

        ContentRequests.getSpecificContent(`/Dungeons/${index}`, (serverData) => {
            if (!serverData.Rooms) serverData.Rooms = [];
            setDungeon(serverData);
        });
        ContentRequests.getType(`/Dungeons`, (serverTemplate) => {
            setTemplate(serverTemplate);
        })
    }, [content]);



    if (dungeon == null || template == null)
        return (<div className='text-white'>Loading...</div>);

    console.log(template);
    return (
        <div>
            <div className="border-b border-gray-200 flex flex-wrap ">
                <div className="mt-3 me-4">
                    <button type='button' className='flex flex-column' onClick={() => {
                        if (hasChangesRef.current && !confirm("Are you sure you want to go back?")) return;

                        return navigate('/dungeons');
                    }}>
                        <kbd className="rtl:rotate-180 inline-flex items-center px-2 py-1.5 text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">
                            <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16">
                                <path d="M8.766.566A2 2 0 0 0 6.586 1L1 6.586a2 2 0 0 0 0 2.828L6.586 15A2 2 0 0 0 10 13.586V2.414A2 2 0 0 0 8.766.566Z" />
                            </svg>
                            <span className="sr-only">Arrow key left</span>
                        </kbd>
                        <p className="ml-2 cursor rounded-lg group text-white">Back</p>
                    </button>
                </div>
                <p className='inline-flex items-center justify-center p-3 border-b-2 border-transparent rounded-t-lg text-white group'>
                    {dungeon.Name}
                </p>
                <div className="">
                    <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
                        <li className="me-2">
                            <button onClick={() => setSelectedTab(DungeonEditTabs.MetaData)}
                                className="inline-flex items-center justify-center p-4 border-transparent rounded-t-lg group text-white" active={(selectedTab == DungeonEditTabs.MetaData) + ""}>
                                <svg className="w-4 h-4 me-2 text-white group-hover:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                                </svg>Meta Data
                            </button>
                        </li>
                        <li className="me-2">
                            <button onClick={() => setSelectedTab(DungeonEditTabs.Rooms)}
                                className="inline-flex items-center justify-center p-4 border-transparent rounded-t-lg group text-white " active={(selectedTab == DungeonEditTabs.Rooms) + ""} aria-current="page">
                                <svg className="w-4 h-4 me-2 text-white group-hover:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                </svg>Rooms
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5'>
                {
                    selectedTab != DungeonEditTabs.MetaData ?
                        ""
                        :
                        <GenericEditor
                            hidden={(selectedTab != DungeonEditTabs.MetaData)}
                            template={template}
                            data={dungeon}
                            escapeProps={["Rooms"]}
                            hasChangesRef={hasChangesRef}
                            onSave={(newData) => {
                                ContentRequests.savePartialContent(`Dungeons/${index}`, newData, data => {
                                    if (data) {
                                        Object.assign(dungeonOriginal, data);
                                        alert('Saved');
                                        setContent(content);
                                    }
                                });
                            }}>

                        </GenericEditor>
                }
                {
                    selectedTab != DungeonEditTabs.Rooms ?
                        ""
                        :
                        <div hidden={(selectedTab != DungeonEditTabs.Rooms)} id="styled-dashboard" role="tabpanel">

                            <div>
                                <div>
                                    <div className="p-4 rounded-lg bg-gray-50 text-sm text-gray-500">Create Standart room with dimensions:
                                        <div className="flex items-center space-x-2">
                                            <input type="number" placeholder="Width (X)" className="p-1 rounded border border-gray-300" id="standart-xInput"
                                            //onChange={(e) => setX(e.target.value)} // Update state with X value
                                            />
                                            <span>by</span>
                                            <input type="number" placeholder="Height (Y)" className="p-1 rounded border border-gray-300" id="standart-yInput"
                                            //onChange={(e) => setY(e.target.value)} // Update state with Y value
                                            />
                                            <button type="button" className='w-5 p-1 ms-1 rounded-full bg-green-600 hover:bg-green-500'
                                                onClick={() => {
                                                    const x = document.querySelector('#standart-xInput').value;
                                                    const y = document.querySelector('#standart-yInput').value;

                                                    if (!x || !y || isNaN(x) || isNaN(y)) {
                                                        alert("Please enter valid or non-nullable dimensions");
                                                        return;
                                                    }
                                                    let entity = CreateEmptyRoom(Number(x), Number(y));
                                                    dungeon.Rooms.push(entity);
                                                    ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, (data) => {
                                                        if (data) {
                                                            Object.assign(dungeonOriginal, data);
                                                            alert('Saved');
                                                            setContent(content);
                                                        }
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-left'>
                                        {dungeon.Rooms.filter(x => !x.IsBossRoom && !x.IsFinalBossRoom && !x.IsRewardRoom)
                                            .map((x, i) =>
                                                <DungeonRoomListItem
                                                    key={`StandardRoom-${i}-${(new Date()).getMilliseconds()}`}
                                                    dungeonId={dungeon.Id}
                                                    roomData={x}
                                                    roomIndex={i}
                                                    onEdit={(data) => {
                                                        setEditingRoom(true);
                                                        setEditingRoomIndex(dungeon.Rooms.indexOf(x), 1);
                                                        setEditingRoomName(`Standard Room ${i + 1}`);
                                                    }}
                                                    onDuplicate={(data) => {
                                                        dungeon.Rooms.push(JSON.parse(JSON.stringify(data)));
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                    onDelete={(data) => {
                                                        dungeon.Rooms.splice(dungeon.Rooms.indexOf(x), 1);
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, data);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                >
                                                </DungeonRoomListItem>
                                            )}
                                    </div>
                                </div>
                                <div>
                                    <div className="p-4 rounded-lg bg-gray-50 text-sm text-gray-500">Create Boss Room with dimensions:
                                        <div className="flex items-center space-x-2">
                                            <input type="number" placeholder="Width (X)" className="p-1 rounded border border-gray-300" id="boss-xInput" />
                                            <span>by</span>
                                            <input type="number" placeholder="Height (Y)" className="p-1 rounded border border-gray-300" id="boss-yInput" />
                                            <button type="button" className='w-5 p-1 ms-1 rounded-full bg-green-600 hover:bg-green-500'
                                                onClick={() => {
                                                    const x = document.querySelector('#boss-xInput').value;
                                                    const y = document.querySelector('#boss-yInput').value;

                                                    if (!x || !y || isNaN(x) || isNaN(y)) {
                                                        alert("Please enter valid or non-nullable dimensions");
                                                        return;
                                                    }
                                                    let entity = CreateEmptyRoom(Number(x), Number(y));
                                                    entity.IsBossRoom = true;
                                                    dungeon.Rooms.push(entity);
                                                    ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, (data) => {
                                                        if (data) {
                                                            Object.assign(dungeonOriginal, data);
                                                            alert('Saved');
                                                            setContent(content);
                                                        }
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-left'>
                                        {dungeon.Rooms.filter(x => x.IsBossRoom && !x.IsFinalBossRoom && !x.IsRewardRoom)
                                            .map((x, i) =>
                                                <DungeonRoomListItem
                                                    key={`BossRoom-${i}-${(new Date()).getMilliseconds()}`}
                                                    dungeonId={dungeon.Id}
                                                    roomData={x}
                                                    roomIndex={i}
                                                    onEdit={(data) => {
                                                        setEditingRoom(true);
                                                        setEditingRoomIndex(dungeon.Rooms.indexOf(x), 1);
                                                        setEditingRoomName(`Boss Room ${i + 1}`);
                                                    }}
                                                    onDuplicate={(data) => {
                                                        dungeon.Rooms.push(JSON.parse(JSON.stringify(data)));
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                    onDelete={(data) => {
                                                        dungeon.Rooms.splice(dungeon.Rooms.indexOf(x), 1);
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                >
                                                </DungeonRoomListItem>
                                            )}
                                    </div>
                                </div>
                                <div>
                                    <div className="p-4 rounded-lg bg-gray-50 text-sm text-gray-500">Create Reward Room with dimensions:
                                        <div className="flex items-center space-x-2">
                                            <input type="number" placeholder="Width (X)" className="p-1 rounded border border-gray-300" id="reward-xInput" />
                                            <span>by</span>
                                            <input type="number" placeholder="Height (Y)" className="p-1 rounded border border-gray-300" id="reward-yInput" />
                                            <button type="button" className='w-5 p-1 ms-1 rounded-full bg-green-600 hover:bg-green-500'
                                                onClick={() => {
                                                    const x = document.querySelector('#reward-xInput').value;
                                                    const y = document.querySelector('#reward-yInput').value;

                                                    if (!x || !y || isNaN(x) || isNaN(y)) {
                                                        alert("Please enter valid or non-nullable dimensions");
                                                        return;
                                                    }
                                                    let entity = CreateEmptyRoom(Number(x), Number(y));
                                                    entity.IsRewardRoom = true;
                                                    dungeon.Rooms.push(entity);
                                                    ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, (data) => {
                                                        if (data) {
                                                            Object.assign(dungeonOriginal, data);
                                                            alert('Saved');
                                                            setContent(content);
                                                        }
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-left'>
                                        {dungeon.Rooms.filter(x => !x.IsBossRoom && !x.IsFinalBossRoom && x.IsRewardRoom)
                                            .map((x, i) =>
                                                <DungeonRoomListItem
                                                    key={`RewardRoom-${i}-${(new Date()).getMilliseconds()}`}
                                                    dungeonId={dungeon.Id}
                                                    roomData={x}
                                                    roomIndex={i}
                                                    onEdit={(data) => {
                                                        setEditingRoom(true);
                                                        setEditingRoomIndex(dungeon.Rooms.indexOf(x), 1);
                                                        setEditingRoomName(`Reward Room ${i + 1}`);
                                                    }}
                                                    onDuplicate={(data) => {
                                                        dungeon.Rooms.push(JSON.parse(JSON.stringify(data)));
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                    onDelete={(data) => {
                                                        dungeon.Rooms.splice(dungeon.Rooms.indexOf(x), 1);
                                                        Object.assign(dungeonOriginal, dungeon);
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                >
                                                </DungeonRoomListItem>
                                            )}
                                    </div>
                                </div>
                                <div>
                                    <div className="p-4 rounded-lg bg-gray-50 text-sm text-gray-500">Create Final Boss Room with dimensions:
                                        <div className="flex items-center space-x-2">
                                            <input type="number" placeholder="Width (X)" className="p-1 rounded border border-gray-300" id="finalBoss-xInput" />
                                            <span>by</span>
                                            <input type="number" placeholder="Height (Y)" className="p-1 rounded border border-gray-300" id="finalBoss-yInput" />
                                            <button type="button" className='w-5 p-1 ms-1 rounded-full bg-green-600 hover:bg-green-500'
                                                onClick={() => {
                                                    const x = document.querySelector('#finalBoss-xInput').value;
                                                    const y = document.querySelector('#finalBoss-yInput').value;

                                                    if (!x || !y || isNaN(x) || isNaN(y)) {
                                                        alert("Please enter valid or non-nullable dimensions");
                                                        return;
                                                    }
                                                    let entity = CreateEmptyRoom(Number(x), Number(y));
                                                    entity.IsFinalBossRoom = true;
                                                    dungeon.Rooms.push(entity);
                                                    ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, (data) => {
                                                        if (data) {
                                                            Object.assign(dungeonOriginal, data);
                                                            alert('Saved');
                                                            setContent(content);
                                                        }
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true" className="nz sb"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-left'>
                                        {dungeon.Rooms.filter(x => !x.IsBossRoom && x.IsFinalBossRoom && !x.IsRewardRoom)
                                            .map((x, i) =>
                                                <DungeonRoomListItem
                                                    key={`FinalBossRoom-${i}-${(new Date()).getMilliseconds()}`}
                                                    dungeonId={dungeon.Id}
                                                    roomData={x}
                                                    roomIndex={i}
                                                    onEdit={(data) => {
                                                        setEditingRoom(true);
                                                        setEditingRoomIndex(dungeon.Rooms.indexOf(x), 1);
                                                        setEditingRoomName(`Final Boss Room ${i + 1}`);
                                                    }}
                                                    onDuplicate={(data) => {
                                                        dungeon.Rooms.push(JSON.parse(JSON.stringify(data)));
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                    onDelete={(data) => {
                                                        dungeon.Rooms.splice(dungeon.Rooms.indexOf(x), 1);
                                                        ContentRequests.savePartialContent(`Dungeons/${index}`, dungeon, data => {
                                                            if (data) {
                                                                Object.assign(dungeonOriginal, dungeon);
                                                                alert('Saved');
                                                                setContent(content);
                                                            }
                                                        });
                                                    }}
                                                >
                                                </DungeonRoomListItem>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>

            {
                !editingRoom ? null :
                    <div className='absolute flex w-screen h-screen margin-auto top-0 left-0 text-center justify-center align-middle'>
                        <div
                            className='absolute cursor-pointer flex w-screen h-screen margin-auto top-0 left-0 justify-center bg-popup'
                            onClick={() => {
                                if (confirm('Are you sure you want to discard changes?')) {
                                    setEditingRoom(false);
                                }
                            }}
                        >
                        </div>
                        <div className='min-w-40 w-2/5 h-5/6 mt-20 z-10'>
                            <DungeonRoomEdit
                                roomData={dungeon.Rooms[editingRoomIndex]}
                                roomIndex={editingRoomIndex}
                                roomName={editingRoomName}
                                onSave={(roomData) => {
                                    ContentRequests.savePartialContent(`Dungeons/${index}/Rooms/${editingRoomIndex}`, roomData, data => {
                                        if (data) {
                                            dungeon.Rooms[editingRoomIndex] = data;
                                            Object.assign(dungeonOriginal, dungeon);
                                            alert('Saved');
                                            setContent(content);
                                            setEditingRoom(false);
                                        }
                                    });
                                }}
                            >
                            </DungeonRoomEdit>
                        </div>
                    </div>
            }
        </div>
    );
}

export default DungeonEdit;